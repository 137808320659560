import React from 'react'
import OurTeam from '../components/OurTeam'

function TeamPage() {
  return (
    <div className='mt-5'>
        <OurTeam/>
    </div>
  )
}

export default TeamPage