import React from "react";

function MyProfile() {
  return (
    <div className="vh-100 bg-secondary d-flex justify-content-center align-items-center">
      <h3>My Profile</h3>
    </div>
  );
}

export default MyProfile;
