import React from 'react'
import Events from '../components/Events'

function EventPage() {
  return (
    <div className='mt-5'>
        <Events/>
    </div>
  )
}

export default EventPage