import React from 'react'

function TopStrip() {
  return (
    <div className=' bg-success text-light  text-center '>
        <h1>SDJFN</h1>
    </div>
  )
}

export default TopStrip