import React from 'react'

function MyCart() {
  return (
    <div className="vh-100 bg-secondary d-flex justify-content-center align-items-center">
      <h3>My Cart</h3>
    </div>
  )
}

export default MyCart