import React from 'react'
import ContactUs from '../components/ContactUs'

function ContactUsPage() {
  return (
    <div className='mt-5'>
        <ContactUs/>
    </div>
  )
}

export default ContactUsPage