import React from 'react'
import Gallery from '../components/Gallery'

function GalleryPage() {
  return (
    <div className='mt-5'>
        <Gallery/>
    </div>
  )
}

export default GalleryPage