import React from 'react'
import ProductList from '../components/ProductList'

function Products() {
  return (
    <div className='mt-5'>
        <ProductList/>
    </div>
  )
}

export default Products