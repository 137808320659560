import React from 'react'

function Notification() {
  return (
    <div className="vh-100 bg-secondary d-flex justify-content-center align-items-center">
      <h3>Notifications</h3>
    </div>
  )
}

export default Notification